import React, { useEffect, useRef, useCallback } from 'react';
import { useStore, StoreTypes } from '../../../context';
import * as types from '../../../constants/actionTypes';
import { KeyboardType } from 'constants/keyboardType';
import { useConvertJSONToSVG } from 'customHooks/canvas';
import { useFabricListener } from 'customHooks/fabric/listener';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

import { HotKeys } from 'react-hotkeys';
const STEP = 5;

const FabricCanvas = props => {
  const { isDrawingMode, className } = props;
  const [
    {
      fullWidthInfo,
      scale,
      offsetX,
      offsetY,
      objectPointerEventStatus
    }, readerDispatch
  ] = useStore(StoreTypes.reader);
  const [{ books, bookId, style }] = useStore(StoreTypes.books);
  const { width, height } = style;
  const [{ isActive, activeCanvasObject }, dispatch] = useStore(
    StoreTypes.canvas
  );
  const canvasRef = useRef(null);
  const book = books.find(book => book.bookId === bookId);
  const { convertJSONToSVG } = useConvertJSONToSVG();

  const fabricRef = useRef()

  const removeCanvasObjectHandler = useCallback(() => {
    EventBus.emit({ event: CanvasEvent.RemoveCanvasObjectEvent });
  }, []);

  const modifyCanvasObjectHandler = useCallback((object, property, value) => {
    EventBus.emit({
      event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
      payload: { object, property, value }
    });
  }, []);

  const moveSelected = useCallback(
    direction => {
      let property;
      let value;
      if (activeCanvasObject) {
        switch (direction) {
          case KeyboardType.LEFT:
            property = 'left';
            value = activeCanvasObject[property] - STEP;
            break;
          case KeyboardType.UP:
            property = 'top';
            value = activeCanvasObject[property] - STEP;
            break;
          case KeyboardType.RIGHT:
            property = 'left';
            value = activeCanvasObject[property] + STEP;
            break;
          case KeyboardType.DOWN:
            property = 'top';
            value = activeCanvasObject[property] + STEP;
            break;
          default:
            break;
        }
        modifyCanvasObjectHandler(activeCanvasObject, property, value);
      }
    },
    [activeCanvasObject, modifyCanvasObjectHandler]
  );

  useFabricListener('keydown', e => {
    if (e.repeat || e.target.tagName.toLowerCase() === 'input') {
      return;
    }
    const key = e.which || e.keyCode;
    switch (key) {
      case 37:
        moveSelected(KeyboardType.LEFT);
        break;
      case 38:
        moveSelected(KeyboardType.UP);
        break;
      case 39:
        moveSelected(KeyboardType.RIGHT);
        break;
      case 40:
        moveSelected(KeyboardType.DOWN);
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    dispatch({
      type: types.CANVAS_INITIALIZE,
      el: canvasRef.current,
      width,
      height,
      isDrawingMode
    });
    // eslint-disable-next-line
  }, [bookId]);

  useEffect(() => {
    if (book && width > 0 && height > 0) {
      dispatch({
        type: types.CANVAS_RESIZE,
        width,
        height
      });
      convertJSONToSVG({
        keepCanvas: true
      });
    }
    // eslint-disable-next-line
  }, [width, height, book]);

  useEffect(() => {
    console.log(canvasRef.current);
  }, [canvasRef]);

  const keyMap = {
    DELETE: ['del', 'backspace']
  };

  const handlers = {
    DELETE: () => removeCanvasObjectHandler()
  };

  useEffect(() => {
    if (!fabricRef.current) return;

    // console.log((913 * 1.853 - 913) / 2);
    // console.log(-756.62);
    // console.log(-756.62 + (913 * 1.853 - 913) / 2);

    // const fabricW = width
    // const fabricH = height
    // const scaleGapX = ((fabricW) * scale - fabricW) / 2
    // const scaleGapY = (fabricH * scale - fabricH) / 2

    // const newScale = 1 / scale
    // const newOffsetX = -(offsetX * scale + scaleGapX)
    // const newOffsetY = -(offsetY + scaleGapY)
    // const scaleX = (width / fullWidthInfo.scale) * newScale

    // const scaleY = newScale
    // //fabricRef.current.style.transform = `scaleX(${scaleX}) scaleY(${scaleY}) translate(${newOffsetX}px,${newOffsetY}px)`
    // //
    // fabricRef.current.style.transform = `scale(${newScale}) translate(${-scaleX}px,${newOffsetY}px)`

    // var e = window.event;

    // if (!e) return;
    // var posX = e.clientX;
    // var posY = e.clientY;

    // const baseGapX = 22//ReaderView bookMain width:cale(100%-44px)
    // console.log("posX", posX - baseGapX, posY)
    // console.log("baseGapX", baseGapX, scaleGapX, scaleGapY)
    // console.log("offsetX", offsetX);
    // console.log("scaleX", scaleX);
    // console.log(scale, fullWidthInfo.scale, "------", offsetX, offsetY, width, height, newScale);

    const fabricW = width
    const fabricH = height
    const scaleGapX = (fabricW * scale - fabricW) / 2
    const scaleGapY = (fabricH * scale - fabricH) / 2

    const newScaleX = 1 / (scale)
    const newScaleY = 1 / (scale)
    const newOffsetX = -(offsetX / fullWidthInfo.scale + scaleGapX / fullWidthInfo.scale)
    const newOffsetY = -(offsetY + scaleGapY)
    fabricRef.current.style.transform = `scaleX(${newScaleX}) scaleY(${newScaleY}) translate(${newOffsetX}px,${newOffsetY}px)`
  }, [fullWidthInfo, height, offsetX, offsetY, readerDispatch, scale, width, isActive])

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <div
        id="FabricElement"
        className={className}
        ref={fabricRef}
        style={{
          display: isActive ? 'block' : 'none'
        }}
      >
        <canvas ref={canvasRef} id="mainCanvas" />
      </div>
    </HotKeys>
  );
};

export default FabricCanvas;
