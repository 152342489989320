import React, { useState, useRef, useEffect, useCallback } from "react";
import { useStore, StoreTypes } from "context";
import DragModal from "./DragModal";
import styles from "./index.module.scss";
import { QRCodeSVG } from "qrcode.react";
import uuid from "util/uuid";
import * as types from "constants/actionTypes";
import copyIcon from "components/PaiQuiz/copyIcon.png";
import noContent from "components/PaiQuiz/noContent.png";
import BaseSnackbar from "components/BaseSnackbar";
import { LottiePlayer2 } from "../LottieLoading/lottiePlayer";
import { StepStatus } from "constants/aiSpeak";

const naniSpeakUrl = process.env.REACT_APP_NANISPEAK_URL;

const AiSpeakContent = ({ stepStatus, isLoading, paperData, qrCodeValue }) => {
  const { paperId, displayName } = paperData;
  const naniSpeakLink = useRef(null);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal, open } = snackbarState;
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const copyLink = (newState) => {
    navigator.clipboard.writeText(naniSpeakLink.current.textContent);
    setSnackbarState({ open: true, ...newState });
  };

  switch (stepStatus) {
    case StepStatus.Preparing: {
      return (
        <div className={styles.emptyContent}>
          <img src={noContent} alt="題目準備中，敬請期待 ～" />
          題目準備中，敬請期待 ～
        </div>
      );
    }
    case StepStatus.Testing: {
      const quizSrc = `${naniSpeakUrl}/quiz/${paperId}?code=${qrCodeValue}`;

      return (
        <>
          {isLoading && (
            <div className={styles.loadingContent}>
              <LottiePlayer2 />
              <p>等待中...</p>
            </div>
          )}
          {!isLoading && (
            <>
              <div className={styles.immediateContent}>
                <div className={styles.qrCode}>
                  <QRCodeSVG value={quizSrc} />
                </div>

                <div className={styles.qrTextBlock}>
                  <div className={styles.qrTitle}>AI 練口說</div>
                  <div className={styles.qrHeading}>{displayName}</div>
                  <div className={styles.qrLinkArea}>
                    <div className={styles.linkText}>
                      <span ref={naniSpeakLink}>{quizSrc}</span>
                    </div>
                    <button
                      className={`${styles.linkButton} ${styles.linkButtonDesktop}`}
                      type="button"
                      onClick={() =>
                        copyLink({ vertical: "bottom", horizontal: "left" })
                      }
                    >
                      <img src={copyIcon} alt="複製" />
                      複製連結
                    </button>
                    <button
                      className={`${styles.linkButton} ${styles.linkButtonMobile}`}
                      type="button"
                      onClick={() =>
                        copyLink({ vertical: "top", horizontal: "center" })
                      }
                    >
                      <img src={copyIcon} alt="複製" />
                      複製連結
                    </button>
                  </div>
                  {/* <div className={styles.qrText}>
                <span>結束時間：</span>
                <span>2024/06/XX 11:XX</span>
              </div> */}
                </div>
              </div>
              <BaseSnackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                handleClose={handleSnackbarClose}
                message={"連結已複製"}
              />
            </>
          )}
        </>
      );
    }
    case StepStatus.EndClose: {
      return (
        <div className={styles.endCloseContent}>
          <p className={styles.confirmDialog}>
            建議預先查看<span>「作答紀錄」</span>並儲存，
            <br />
            若關閉本視窗，會造成紀錄遺失並無法查看測驗結果。
          </p>
        </div>
      );
    }
    case StepStatus.UnLogin:
      return <div className={styles.emptyContent}>請登入以使用功能</div>;
    default:
      return null;
  }
};

const AiSpeakBottom = ({
  stepStatus,
  isLoading,
  onClose,
  onBack,
  qrCodeValue,
}) => {
  const resultSrc = `${naniSpeakUrl}/review/${qrCodeValue}`;

  switch (stepStatus) {
    case StepStatus.Preparing:
    case StepStatus.UnLogin: {
      return <></>;
    }
    case StepStatus.Testing: {
      return (
        !isLoading && (
          <div className={`${styles.actionsButton} ${styles.primaryButton}`}>
            {window.electron ? (
              <button
                className={`${styles.resultLink} ${styles.forElectron}`}
                onClick={() => {
                  window.electron &&
                    window.electron.ipcRenderer.send("openExternal", resultSrc);
                }}
              >
                作答記錄
              </button>
            ) : (
              <a href={resultSrc} target="_blank" rel="noopener noreferrer">
                作答記錄
              </a>
            )}
          </div>
        )
      );
    }
    case StepStatus.EndClose: {
      return (
        <>
          <div className={`${styles.actionsButton} ${styles.secondary}`}>
            <button type="button" onClick={onBack}>
              取消
            </button>
          </div>
          <div className={`${styles.actionsButton} ${styles.primaryButton}`}>
            <button type="button" onClick={onClose}>
              確定
            </button>
          </div>
        </>
      );
    }
    default:
      return null;
  }
};

const AiSpeak = () => {
  const [{ token }] = useStore(StoreTypes.user);
  const [{ aiSpeakSrc }, readerDispatch] = useStore(StoreTypes.reader);
  const [paperData, setPaperData] = useState({
    paperId: "",
    displayName: "",
  });
  const [qrCodeValue] = useState(uuid());
  const [stepStatus, setStepStatus] = useState(StepStatus.Preparing);
  const [isLoading, setIsLoading] = useState(false);
  const [, globalDispatch] = useStore(StoreTypes.global);
  const title = {
    [StepStatus.Preparing]: "題目準備中",
    [StepStatus.Testing]: "請掃描QR code或複製連結加入",
    [StepStatus.EndClose]: "確定要關閉嗎？",
    [StepStatus.UnLogin]: "請登入",
  };

  useEffect(() => {
    if (aiSpeakSrc === "unLogin") {
      setStepStatus(StepStatus.UnLogin);
      readerDispatch({ type: types.SET_AISPEAK_SRC, aiSpeakSrc: "" });
      return;
    }
    if (!aiSpeakSrc) return;

    const getMappingApi = async () => {
      setIsLoading(true);
      try {
        const getOne = await fetch(
          `${process.env.REACT_APP_NANISPEAK_API}/v1/papers/get/one`,
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ paperCode: aiSpeakSrc }),
          }
        );
        const { data, err } = await getOne.json();

        if (err !== 0 || data === null) return;

        setPaperData({
          paperId: data.paperId,
          displayName: data.displayName,
        });
        setStepStatus(StepStatus.Testing);
      } catch (err) {
        console.log("AiSpeak getMappingApi error", err);
      } finally {
        setIsLoading(false);
      }
    };
    getMappingApi();

    return () => {
      readerDispatch({ type: types.SET_AISPEAK_SRC, aiSpeakSrc: "" });
    };
  }, [aiSpeakSrc, readerDispatch, token]);

  const handleClose = () => {
    if (stepStatus === StepStatus.Testing) {
      setStepStatus(StepStatus.EndClose);
    } else {
      globalDispatch({ type: types.CLOSE_AISPEAK_MODAL });
    }
  };

  const handleGoBack = () => {
    setStepStatus(StepStatus.Testing);
  };

  const handleShowCloseButton = () => {
    return stepStatus !== StepStatus.EndClose;
  };

  return (
    <DragModal
      title={title[stepStatus]}
      showCloseButton={handleShowCloseButton()}
      onCloseButtonClick={handleClose}
      content={
        <AiSpeakContent
          stepStatus={stepStatus}
          isLoading={isLoading}
          paperData={paperData}
          qrCodeValue={qrCodeValue}
        />
      }
      bottom={
        <AiSpeakBottom
          stepStatus={stepStatus}
          isLoading={isLoading}
          onClose={handleClose}
          onBack={handleGoBack}
          qrCodeValue={qrCodeValue}
        />
      }
    />
  );
};

export default AiSpeak;
