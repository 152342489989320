import { EventBus } from 'events/EventBus';
import { PainterMode } from 'constants/painterModes';
import { PainterToolType, MeasureToolType } from 'constants/painterTypes';
import {
  ActivityEvent,
  InteractiveObjectEvent,
  ReaderEvent,
  ReaderToolsEvent,
  CanvasEvent
} from 'events/EventTypes';
import {
  ReaderToolType,
  ReaderZoomType,
  SideBarType
} from 'constants/ReaderTools';
import { BookFlipType } from 'constants/flipTypes';
import { gaEventReaderToolsClick } from 'components/AppService/GAService';
import { CategoryType } from 'constants/gaEventType';

const closeToolbox = () => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickReaderToolBoxEvent,
    payload: {
      isToolboxShow: false
    }
  })
}

const changePainterMode = ({ event, painterMode, painterToolType }) => {
  EventBus.emit({ event, payload: { painterMode, painterToolType } });
  closeToolbox();
};

const addImageClickHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickInsertImageEvent,
    payload: { painterMode: PainterMode.InsertImage }
  });
  setReaderToolType(ReaderToolType.Img);
};

const addTextClickHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickInsertTextEvent,
    payload: { painterMode: PainterMode.InsertText }
  });
  setReaderToolType(ReaderToolType.Text);
};

const toggleBookmarkHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ToggleBookmarkEvent
  });
  closeToolbox();
};

const toggleActivityPanel = () => {
  EventBus.emit({
    event: ActivityEvent.ToggleActivityPanelEvent,
    payload: {}
  });
  closeToolbox();
};

const setReaderToolType = type => {
  EventBus.emit({
    event: ReaderToolsEvent.SetReaderToolTypeEvent,
    payload: {
      readerToolType: type
    }
  });
  type !== ReaderToolType.Toolbox && closeToolbox();
}

const toggleSideBarHandler = sideBarType => e => {
  gaEventReaderToolsClick({
    category: CategoryType.ReaderTool,
    action: ReaderToolType.Menu,
    label: ReaderToolType.Menu
  });
  EventBus.emit({
    event: ReaderToolsEvent.ToggleSideBarEvent,
    payload: { sideBarType }
  });
  closeToolbox();
};

const toggleFullScreen = () => {
  EventBus.emit({
    event: ReaderToolsEvent.ToggleFullScreen
  });
}

export default {
  [ReaderToolType.Expression]: {
    title: '表情',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Expression,
        label: ReaderToolType.Expression
      });

      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag);
    },
    icon: 'Expression',
  },
  [ReaderToolType.SynchronousCamera]: {
    title: '遙控器',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.SynchronousCamera,
        label: ReaderToolType.SynchronousCamera
      });

      EventBus.emit({
        event: ReaderToolsEvent.SetSyncCameraEvent,
        payload: { isOpen: null },
      });
      setReaderToolType(ReaderToolType.Drag);
    },
    icon: 'Remote',
  },
  [ReaderToolType.BackToCatalog]: {
    // title: '回目次',
    title: 'ReaderToolTitle_BackToCatalog',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.BackToCatalog,
        label: ReaderToolType.BackToCatalog
      });

      EventBus.emit({ event: ReaderEvent.GoBackCatalogEvent });
      closeToolbox();
    },
    icon: 'ContentBack',
  },
  [ReaderToolType.StickyNote]: {
    title: '便利貼',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.StickyNote,
        label: ReaderToolType.StickyNote
      });

      EventBus.emit({
        event: ReaderToolsEvent.CreateCourseInteractiveItemEvent,
        payload: { painterMode: ReaderToolType.StickyNote, contentType: ReaderToolType.StickyNote, isNew: true }
      });
      setReaderToolType(ReaderToolType.StickyNote);
    },
    icon: 'StickyNote',
  },
  [ReaderToolType.Link]: {
    title: '連結',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Link,
        label: ReaderToolType.Link
      });

      EventBus.emit({
        event: ReaderToolsEvent.CreateCourseInteractiveItemEvent,
        payload: { painterMode: ReaderToolType.Link, contentType: ReaderToolType.Link, isNew: true }
      });
      setReaderToolType(ReaderToolType.Link);
    },
    icon: 'Link',
  },
  [ReaderToolType.MathTools]: {
    title: '尺規作圖',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.MathTools,
        label: ReaderToolType.MathTools
      });

      EventBus.emit({ event: ReaderToolsEvent.ClickMathToolsEvent, payload: { mathToolsInfo: {} } });
      closeToolbox();
    },
    icon: 'MathTool',
  },
  [ReaderToolType.Bookshelf]: {
    // title: '書櫃',
    title: 'ReaderToolTitle_Bookshelf',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Bookshelf,
        label: ReaderToolType.Bookshelf
      });
    },
    icon: 'Bookcase',
  },
  [ReaderToolType.Menu]: {
    // title: '目次',
    title: 'ReaderToolTitle_Menu',
    clickHandler: toggleSideBarHandler(SideBarType.LEFT),
    icon: 'Content',
  },
  [ReaderToolType.Mark]: {
    // title: '標記文字',
    title: 'ReaderToolTitle_Mark',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Mark,
        label: ReaderToolType.Mark
      });

      EventBus.emit({
        event: ReaderToolsEvent.OnMarkModeEvent
      });
      setReaderToolType(ReaderToolType.Mark)
    },
    icon: 'Mark',
  },
  [ReaderToolType.Drag]: {
    // title: '滑鼠',
    title: 'ReaderToolTitle_Mouse',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Drag,
        label: ReaderToolType.Drag
      });

      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag);
    },
    icon: 'MouseHand',
  },
  [ReaderToolType.Select]: {
    // title: '選取',
    title: 'ReaderToolTitle_Select',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Select,
        label: ReaderToolType.Select
      });

      closeToolbox();
      changePainterMode({
        event: ReaderToolsEvent.ClickSelectEvent,
        painterMode: PainterMode.Selection
      });
      setReaderToolType(ReaderToolType.Select);
    },
    icon: 'Select',
  },
  [ReaderToolType.Painting]: {
    // title: '畫筆',
    title: 'ReaderToolTitle_Painter',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Painting,
        label: ReaderToolType.Painting
      });

      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Pen
      });
      setReaderToolType(ReaderToolType.Painting);
    },
    icon: 'Painting',
  },
  [ReaderToolType.Highlighter]: {
    // title: '螢光筆',
    title: 'ReaderToolTitle_Highlighter',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Highlighter,
        label: ReaderToolType.Highlighter
      });

      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Highlighter
      });
      setReaderToolType(ReaderToolType.Highlighter);
    },
    icon: 'Highlighter',
  },
  [ReaderToolType.Shape]: {
    // title: '形狀',
    title: 'ReaderToolTitle_Shape',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Shape,
        label: ReaderToolType.Shape
      });

      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Shape
      });
      setReaderToolType(ReaderToolType.Shape);
    },
    icon: 'Shape',
  },
  [ReaderToolType.Line]: {
    // title: '線段',
    title: 'ReaderToolTitle_Line',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Line,
        label: ReaderToolType.Line
      });

      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Line
      });
      setReaderToolType(ReaderToolType.Line);
    },
    icon: 'Line',
  },
  [ReaderToolType.DeleteAll]: {
    // title: '全部刪除',
    title: 'ReaderToolTitle_DeleteAll',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.DeleteAll,
        label: ReaderToolType.DeleteAll
      });

      EventBus.emit({ event: ReaderToolsEvent.ClickEraseAllEvent });
      closeToolbox();
    },
    icon: 'DeleteAll',
  },
  [ReaderToolType.Delete]: {
    // title: '部分刪除',
    title: 'ReaderToolTitle_Delete',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Delete,
        label: ReaderToolType.Delete
      });

      changePainterMode({
        event: ReaderToolsEvent.ClickEraserEvent,
        painterMode: PainterMode.Eraser
      })
      setReaderToolType(ReaderToolType.Delete);
    },
    icon: 'Delete',
  },
  [ReaderToolType.ArrowLeft]: {
    // title: '左翻',
    title: { [BookFlipType.LEFT_TO_RIGHT]: 'ReaderToolTitle_LeftFlip', [BookFlipType.RIGHT_TO_LEFT]: 'ReaderToolTitle_RightFlip' },
    clickHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.ArrowLeft,
          label: ReaderToolType.ArrowLeft
        });

        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      } else {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.ArrowLeft,
          label: ReaderToolType.ArrowLeft
        });

        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      }
      closeToolbox();
    },
    icon: 'ArrowLeft',
  },
  [ReaderToolType.ArrowRight]: {
    // title: '右翻',
    title: { [BookFlipType.LEFT_TO_RIGHT]: 'ReaderToolTitle_RightFlip', [BookFlipType.RIGHT_TO_LEFT]: 'ReaderToolTitle_LeftFlip' },
    clickHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.ArrowRight,
          label: ReaderToolType.ArrowRight
        });

        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      } else {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.ArrowRight,
          label: ReaderToolType.ArrowRight
        });

        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      }
      closeToolbox();
    },
    icon: 'ArrowRight',
  },
  [ReaderToolType.PageSearcher]: {
    // title: '頁數選擇',
    title: 'ReaderToolTitle_PageSearcher',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.PageSearcher,
        label: ReaderToolType.PageSearcher
      });

      EventBus.emit({
        event: ReaderToolsEvent.TogglePageSearcherEvent
      });
      closeToolbox();
    },
    clickLeftHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.PageSearcherPrevPage,
          label: ReaderToolType.PageSearcherPrevPage
        });

        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      } else {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.PageSearcherNextPage,
          label: ReaderToolType.PageSearcherNextPage,
        });

        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      }
      closeToolbox();
    },
    clickRightHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.PageSearcherNextPage,
          label: ReaderToolType.PageSearcherNextPage,
        });

        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      } else {
        gaEventReaderToolsClick({
          category: CategoryType.ReaderTool,
          action: ReaderToolType.PageSearcherPrevPage,
          label: ReaderToolType.PageSearcherPrevPage,
        });

        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      }
      closeToolbox();
    },
    icon: 'PageSearcher',
  },
  [ReaderToolType.FourZoomIn]: {
    // title: '四格放大'
    title: 'ReaderToolTitle_QuarterZoomIn',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.FourZoomIn,
        label: ReaderToolType.FourZoomIn
      });
    }
  },
  [ReaderToolType.AllScreen]: {
    // title: '原尺寸',
    title: 'ReaderToolTitle_OriginalSize',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.AllScreen,
        label: ReaderToolType.AllScreen
      });

      EventBus.emit({
        event: ReaderToolsEvent.SetReaderZoomEvent,
        payload: { type: ReaderZoomType.OriginZoom }
      })
      closeToolbox();
    },
    icon: 'AllScreen',
  },
  [ReaderToolType.IntervalZoomIn]: {
    // title: '區間放大',
    title: 'ReaderToolTitle_SectionZoomIn',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.IntervalZoomIn,
        label: ReaderToolType.IntervalZoomIn
      });

      EventBus.emit({
        event: ReaderEvent.DrawAreaEvent,
        payload: { drawAreaInfo: { isDrawArea: true } }
      });
      setReaderToolType(ReaderToolType.IntervalZoomIn);
    },
    icon: 'IntervalZoomIn',
  },
  [ReaderToolType.FullScreen]: {
    // title: '全螢幕',
    title: 'ReaderToolTitle_FullScreen',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.FullScreen,
        label: ReaderToolType.FullScreen
      });

      toggleFullScreen();
      setReaderToolType(ReaderToolType.FullScreen);
    },
    icon: 'FullScreen',
  },
  [ReaderToolType.Whiteboard]: {
    // title: '白板',
    title: 'ReaderToolTitle_Whiteboard',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Whiteboard,
        label: ReaderToolType.Whiteboard
      });
    },
    icon: 'Whiteboard',
  },
  [ReaderToolType.NumberPicker]: {
    // title: '選號器',
    title: 'ReaderToolTitle_NumberPicker',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.NumberPicker,
        label: ReaderToolType.NumberPicker
      });
    },
    icon: 'NumberPicker',
  },
  [ReaderToolType.Img]: {
    // title: '新增圖片',
    title: 'ReaderToolTitle_InsertImage',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Img,
        label: ReaderToolType.Img
      });

      addImageClickHandler()
    },
    icon: 'Img',
  },
  [ReaderToolType.Text]: {
    // title: '新增文字',
    title: 'ReaderToolTitle_InsertText',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Text,
        label: ReaderToolType.Text
      });

      addTextClickHandler()
    },
    icon: 'Text',
  },
  [ReaderToolType.SVG]: {
    // title: 'SVG物件',
    title: 'ReaderToolTitle_InsertStamp',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.SVG,
        label: ReaderToolType.SVG
      });

      EventBus.emit({ event: ReaderToolsEvent.ClickStampPanelEvent })
      setReaderToolType(ReaderToolType.Menu);
    },
    icon: 'SVG',
  },
  [ReaderToolType.PageMode]: {
    // title: { [true]: '單頁', [false]: '雙頁' },
    title: { [true]: 'ReaderToolTitle_SinglePageMode', [false]: 'ReaderToolTitle_DoublePageMode' },
    clickHandler: isDoublePageMode => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.PageMode,
        label: ReaderToolType.PageMode
      });

      EventBus.emit({
        event: ReaderToolsEvent.TogglePageModeEvent,
        payload: { isDoublePageMode: !isDoublePageMode }
      })
      closeToolbox();
    },
    icon: { [true]: 'OnePage', [false]: 'DoublePage' }
  },
  [ReaderToolType.ReaderOption]: {
    // title: '設定',
    title: 'ReaderToolTitle_Options',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.ReaderOption,
        label: ReaderToolType.ReaderOption
      });

      EventBus.emit({ event: ReaderToolsEvent.ClickReaderOptionPanelEvent })
      closeToolbox();
    },
    icon: 'Setup',
  },
  [ReaderToolType.Bookmark]: {
    // title: '頁籤',
    title: 'ReaderToolTitle_Bookmark',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.ReaderOption,
        label: ReaderToolType.ReaderOption
      });

      toggleBookmarkHandler()
    },
    icon: 'Bookmark',
  },
  [ReaderToolType.Activity]: {
    // title: '活動',
    title: 'ReaderToolTitle_Activity',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Activity,
        label: ReaderToolType.Activity
      });

      toggleActivityPanel()
    },
    icon: 'Activity',
  },
  [ReaderToolType.ExportInteractiveObject]: {
    // title: '匯出',
    title: 'ReaderToolTitle_ExportInteractiveObject',
    clickHandler: e => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.ExportInteractiveObject,
        label: ReaderToolType.ExportInteractiveObject
      });

      EventBus.emit({
        event: InteractiveObjectEvent.ExportInteractiveObjectEvent
      });
    },
    icon: 'Save',
  },
  [ReaderToolType.AttendeeNumber]: {
    // title: '座號',
    title: 'ReaderToolTitle_AttendeeNumber',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.AttendeeNumber,
        label: ReaderToolType.AttendeeNumber
      });
    }
  },
  [ReaderToolType.OfflineReaderToolBox]: {
    // title: '工具箱',
    title: 'ReaderToolTitle_OfflineReaderToolBox',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.OfflineReaderToolBox,
        label: ReaderToolType.OfflineReaderToolBox
      });

      EventBus.emit({ event: ReaderToolsEvent.ClickOfflineReaderToolBoxEvent })
      setReaderToolType(ReaderToolType.OfflineReaderToolBox);
    },
    icon: 'MathToolBox',
  },
  [ReaderToolType.Toolbox]: {
    // title: '工具箱',
    title: 'ReaderToolTitle_Toolbox',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Toolbox,
        label: ReaderToolType.Toolbox
      });

      EventBus.emit({
        event: ReaderToolsEvent.ClickReaderToolBoxEvent,
        payload: {
          isToolboxShow: true
        }
      })
      setReaderToolType(ReaderToolType.Toolbox);
    },
    icon: 'ToolBox',
  },
  [ReaderToolType.Timer]: {
    // title: '計時器',
    title: 'ReaderToolTitle_Timer',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Timer,
        label: ReaderToolType.Timer
      });
    },
    icon: 'Timer',
  },
  [ReaderToolType.Stopwatch]: {
    // title: '馬表',
    title: 'ReaderToolTitle_Stopwatch',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Stopwatch,
        label: ReaderToolType.Stopwatch
      });
    },
    icon: 'Stopwatch',
  },
  [ReaderToolType.MeasureTools]: {
    // title: '測量工具',
    title: 'ReaderToolTitle_MeasureTools',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.MeasureTools,
        label: ReaderToolType.MeasureTools
      });

      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.MeasureTools,
        painterToolType: PainterToolType.Pen
      });
      setReaderToolType(ReaderToolType.MeasureTools);
    },
    icon: 'MeasureTools',
  },
  [ReaderToolType.Protractor]: {
    // title: '量角器',
    title: 'ReaderToolTitle_Protractor',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.Protractor,
        label: ReaderToolType.Protractor
      });

      EventBus.emit({
        event: CanvasEvent.CanvasMeasureToolCreatedEvent,
        payload: {
          type: MeasureToolType.Protractor
        }
      })
    },
    icon: 'MeasureProtractor',
  },
  [ReaderToolType.ShortRuler]: {
    // title: '短尺',
    title: 'ReaderToolTitle_ShortRuler',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.ShortRuler,
        label: ReaderToolType.ShortRuler
      });

      EventBus.emit({
        event: CanvasEvent.CanvasMeasureToolCreatedEvent,
        payload: {
          type: MeasureToolType.ShortRuler
        }
      })
    },
    icon: 'MeasureShortRuler',
  },
  [ReaderToolType.LongRuler]: {
    // title: '長尺',
    title: 'ReaderToolTitle_LongRuler',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.LongRuler,
        label: ReaderToolType.LongRuler
      });

      EventBus.emit({
        event: CanvasEvent.CanvasMeasureToolCreatedEvent,
        payload: {
          type: MeasureToolType.LongRuler
        }
      })
    },
    icon: 'MeasureLongRuler',
  },
  [ReaderToolType.IsoscelesTriangle]: {
    // title: '等腰三角形',
    title: 'ReaderToolTitle_IsoscelesTriangle',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.IsoscelesTriangle,
        label: ReaderToolType.IsoscelesTriangle
      });

      EventBus.emit({
        event: CanvasEvent.CanvasMeasureToolCreatedEvent,
        payload: {
          type: MeasureToolType.IsoscelesTriangle
        }
      })
    },
    icon: 'MeasureIsoscelesTriangle',
  },
  [ReaderToolType.RightTriangle]: {
    // title: '直角三角形',
    title: 'ReaderToolTitle_RightTriangle',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.RightTriangle,
        label: ReaderToolType.RightTriangle
      });

      EventBus.emit({
        event: CanvasEvent.CanvasMeasureToolCreatedEvent,
        payload: {
          type: MeasureToolType.RightTriangle
        }
      })
    },
    icon: 'MeasureRightTriangle',
  },
  [ReaderToolType.StampCollection]: {
    // title: '按鈕收納',
    title: 'ReaderToolTitle_StampCollection',
    clickHandler: () => {
      gaEventReaderToolsClick({
        category: CategoryType.ReaderTool,
        action: ReaderToolType.StampCollection,
        label: ReaderToolType.StampCollection
      });

      EventBus.emit({
        event: ReaderToolsEvent.ToggleStampCollectionBoxShowEvent
      })
      closeToolbox();
    },
    icon: 'StampCollection',
  },
  [ReaderToolType.Undo]: {
    // title: '回復',
    title: 'ReaderToolTitle_Undo',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.UndoEvent })
    },
    icon: 'Undo',
  },
  [ReaderToolType.Redo]: {
    // title: '取消回復',
    title: 'ReaderToolTitle_Redo',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.RedoEvent })
    },
    icon: 'Redo',
  },
  [ReaderToolType.AnswerInteractiveTool]: {
    // title: '快問快答'
    title: 'ReaderToolTitle_AnswerInteractiveTool',
    clickHandler: () => {
      // gaEventReaderToolsClick({
      //   category: CategoryType.ReaderTool,
      //   action: ReaderToolType.AnswerInteractiveTool,
      //   label: ReaderToolType.AnswerInteractiveTool
      // });
    },
    icon: 'AnswerInteractiveTool'
  },
  [ReaderToolType.PaiNote]: {
    // title: '派筆記'
    title: 'ReaderToolTitle_PaiNote',
    clickHandler: () => {
      // gaEventReaderToolsClick({
      //   category: CategoryType.ReaderTool,
      //   action: ReaderToolType.PaiNote,
      //   label: ReaderToolType.PaiNote
      // });
    },
    icon: 'PaiNote'
  },
  [ReaderToolType.SubmitNote]: {
    // title: '筆記回傳'
    title: 'ReaderToolTitle_SubmitNote',
    clickHandler: () => {
      setReaderToolType(ReaderToolType.SubmitNote);
    },
    icon: 'SubmitNote'
  },
  [ReaderToolType.PaiNoteRecord]: {
    // title: '筆記紀錄'
    title: 'ReaderToolTitle_PaiNoteRecord',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePaiNoteRecordEvent
      });
    },
    icon: 'PaiNoteRecord'
  }
  // [ReaderToolType.SyncAnnotation]: {
  //   // title: '儲存雲端',
  //   title: 'ReaderToolTitle_SyncAnnotation',
  //   clickHandler: () => {
  //     console.log('ReaderToolTitle_SyncAnnotation');
  //     EventBus.emit({
  //       event: ReaderToolsEvent.SyncAnnotationToCloud
  //     })
  //   },
  //   icon: 'SyncAnnotation',
  // },
};
