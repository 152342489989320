import React, { useCallback } from 'react';
import Input from 'components/common/Input';
import Textarea from 'components/common/Textarea';
import CheckBox from 'components/common/CheckBox';
import classnames from 'classnames';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import {
  InteractiveObjectContentType,
  InteractiveObjectControlStageType
} from 'constants/interactiveObjectContentTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';
import { ContentTypeDisplayMap } from 'constants/contentType';
import {
  LayoutType,
  LayoutDisplayName
} from 'components/ExtendedContent/ExtendedContentContainer/layout';
import { VideoComponent } from 'components/Tools/PropertyForm/VideoPropertyForm';
import { AreaZoomComponent } from 'components/Tools/PropertyForm/AreaZoomPropertyForm';
import { InternalHtmlWindowComponent } from 'components/Tools/PropertyForm/InternalHtmlWindowComponent';
import { WordGridComponent } from 'components/Tools/PropertyForm/WordGridPropertyForm';
import { VolumeOneWordsComponent } from 'components/Tools/PropertyForm/VolumeOneWordsPropertyForm';
import { SubmenuPropertyForm } from 'components/Tools/PropertyForm/SubmenuPropertyForm';
import { WordExampleComponent } from 'components/Tools/PropertyForm/WordExamplePropertyForm';
import { VocabularyComponent } from 'components/Tools/PropertyForm/VocabularyPropertyForm';
import { SynopsisComponent } from 'components/Tools/PropertyForm/SynopsisPropertyForm';
import { HearingComponent } from 'components/Tools/PropertyForm/HearingPropertyForm';
import { ListeningStoryComponent } from 'components/Tools/PropertyForm/ListeningStoryPropertyForm';
import { AudioComponent } from 'components/Tools/PropertyForm/AudioPropertyForm';
import { MathToolsComponent } from 'components/Tools/PropertyForm/MathToolsPropertyForm';
import { MeasureToolsComponent } from 'components/Tools/PropertyForm/MeasureToolsPropertyForm';
import { useStore } from 'context';
import { StoreTypes } from 'context';
import { InteractiveObjectContentTypeForNaniBookStore } from 'constants/interactiveObjectContentTypes';


const StampSetting = ({ object, inputTextHandler }) => {
  return (
    <div>
      <Input 
        label="按鈕名稱" 
        value={object.stampName}
        onChange={e => inputTextHandler('stampName', e.target.value)}
      />
    </div>
  )
}

const StyleComponent = ({ object, inputTextHandler }) => {
  return (
    <div>
      <label htmlFor="style">Style</label>
      <select
        id="style"
        value={object.style || ''}
        onChange={e => inputTextHandler('style', e.target.value)}
      >
        {!object.controlStage && (
          <option key={-1} value="">
            ---
          </option>
        )}
        {Object.values(LayoutType).map((type, i) => (
          <option key={i} value={type}>
            {LayoutDisplayName[type]}
          </option>
        ))}
      </select>
    </div>
  );
};

const ControlStageComponent = ({ object, inputTextHandler }) => {
  return (
    <div>
      <label htmlFor="controlStage">ControlStage</label>
      <select
        id="controlStage"
        value={object.controlStage || ''}
        onChange={e => inputTextHandler('controlStage', e.target.value)}
      >
        {!object.controlStage && (
          <option key={-1} value="">
            ---
          </option>
        )}
        {Object.values(InteractiveObjectControlStageType).map((type, i) => (
          <option key={i} value={type}>
            {type}
          </option>
        ))}
      </select>
      <br />
      <label htmlFor="revealStage">逐步顯示</label>
      <input
        id="revealStage"
        type="checkbox"
        checked={object.revealStage || false}
        onChange={e => inputTextHandler('revealStage', e.target.checked)}
      />
    </div>
  );
};

const OpacityComponent = ({ object, inputTextHandler }) => {
  return (
    <div>
      <label htmlFor="opacity">Opacity</label>
      <select
        id="opacity"
        value={object.opacity}
        onChange={e => inputTextHandler('opacity', parseInt(e.target.value))}
      >
        <option key={0} value={0}>
          Hide
        </option>
        <option key={1} value={1}>
          Show
        </option>
      </select>
    </div>
  );
};

const AdjustLineAngleComponent = ({ object }) => {
  return (
    <div>
      <label>線段角度</label>
      <br />
      <input
        type="button"
        value="垂直"
        onClick={e =>
          EventBus.emit({
            event: CanvasEvent.AdjustCanvasLineAngleEvent,
            payload: { object, angle: 180 }
          })
        }
      />
      <input
        type="button"
        value="水平"
        onClick={e =>
          EventBus.emit({
            event: CanvasEvent.AdjustCanvasLineAngleEvent,
            payload: { object, angle: 90 }
          })
        }
      />
      <br />
    </div>
  );
};

const VisibleStatusComponent = ({ object, inputTextHandler }) => {
  return (
    <div className={styles.visibleStatusComponent}>
      <div className={styles.title}>可視狀態</div>
      <div className={styles.checkBoxGroup}>
        <label>
          <input
            type="checkbox"
            checked={
              object.isWebVisible === undefined ? true : object.isWebVisible
            }
            onChange={e => inputTextHandler('isWebVisible', e.target.checked)}
          />
          Web版
      </label>
        <label>
          <input
            type="checkbox"
            checked={
              object.isOfflineVisible === undefined ? true : object.isOfflineVisible
            }
            onChange={e => inputTextHandler('isOfflineVisible', e.target.checked)}
          />
          離線版
      </label>
      </div>
    </div>
  )
}

const ClickableStatusComponent = ({ object, inputTextHandler }) => {
  return (
    <div className={styles.clickableStatusComponent}>
      <label>
        <input
          type="checkbox"
          checked={
            object.isClickable || false
          }
          onChange={e => inputTextHandler('isClickable', e.target.checked)}
        />
        可被畫筆點擊
    </label>
    </div>
  )
}

const ControlObjectWhiteList = [
  InteractiveObjectContentType.ControlDisplay,
  InteractiveObjectContentType.ControlStage,
  InteractiveObjectContentType.ControlImage,
  InteractiveObjectContentType.AreaZoom
];

const sourceBlackList = [
  InteractiveObjectContentType.Video,
  InteractiveObjectContentType.InternalHtmlWindow,
  InteractiveObjectContentType.WordGrid,
  InteractiveObjectContentType.WordGlyph,
  InteractiveObjectContentType.WordExample,
  InteractiveObjectContentType.Synopsis,
  InteractiveObjectContentType.VolumeOneWords,
];

const ContentTypeBlackList = [];

function getLabel(content) {
  switch(content) {
    case InteractiveObjectContentType.PaiQuiz:
      return "卷號（paper ID）";
    case InteractiveObjectContentType.AiSpeak:
      return "Code";
    default:
      return "Src";
  }
}

const PropertyForm = ({
  className,
  object,
  setObject,
  contentTypeBlackList = ContentTypeBlackList,
  isSubmenu = false
}) => {
  const [{ books, bookId }] = useStore(StoreTypes.books);
  const book = books.find(book => book.bookId === bookId);
  const isFromNaniBookStore = book && book.from === 'nani';

  const inputTextHandler = useCallback(
    (property, value) => {
      if (isSubmenu) {
        object[property] = value;
        setObject(object);
      } else {
        EventBus.emit({
          event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
          payload: { object, property, value }
        })
      }
    },
    [isSubmenu, object, setObject]
  );

  return (
    <div className={classnames(styles.propertyForm, className)}>
      <Input label="Id" value={object.id} disabled />
      <StampSetting object={object} inputTextHandler={inputTextHandler} />
      <StyleComponent object={object} inputTextHandler={inputTextHandler} />
      {/* {!sourceBlackList.includes(object.contentType) && <Input label="Src" value={object.src} onChange={(e) => inputTextHandler('src', e.target.value.trim())} />} */}
      {!sourceBlackList.includes(object.contentType) && (
        <Textarea
          label={getLabel(object.contentType)}
          value={object.src}
          onChange={e => inputTextHandler('src', e.target.value.trim())}
        />
      )}
      <label htmlFor="contentType">ContentType</label>
      <select
        id="contentType"
        value={object.contentType || ''}
        onChange={e => inputTextHandler('contentType', e.target.value)}
      >
        {!object.contentType && (
          <option key={-1} value="">
            ---
          </option>
        )}

        {!isFromNaniBookStore &&
          Object.values(InteractiveObjectContentType)
            .filter((type) => !contentTypeBlackList.includes(type))
            .map(
              (type, i) =>
                ContentTypeDisplayMap[type] && (
                  <option key={i} value={type}>
                    {ContentTypeDisplayMap[type]}
                  </option>
                )
            )}
        {isFromNaniBookStore &&
          Object.values(InteractiveObjectContentTypeForNaniBookStore).map(
            (type, i) =>
              ContentTypeDisplayMap[type] && (
                <option key={i} value={type}>
                  {ContentTypeDisplayMap[type]}
                </option>
              )
          )}
      </select>
      {ControlObjectWhiteList.includes(object.contentType) && (
        <Textarea
          label="ControlObject"
          value={object.controlObject}
          onChange={e => inputTextHandler('controlObject', e.target.value)}
        />
      )}
      {object.contentType === InteractiveObjectContentType.ControlDisplay && (
        <div>
          <label htmlFor="defaultOpacity">預設隱藏</label>
          <input
            id="defaultOpacity"
            type="checkbox"
            checked={
              object.defaultOpacity === undefined ? true : object.defaultOpacity
            }
            onChange={e => inputTextHandler('defaultOpacity', e.target.checked)}
          />
        </div>
      )}
      {object.contentType === InteractiveObjectContentType.ControlStage && (
        <ControlStageComponent
          object={object}
          inputTextHandler={inputTextHandler}
        />
      )}
      {object.contentType === InteractiveObjectContentType.Stage && (
        <Input
          label="Stage"
          value={object.stage}
          onChange={e => inputTextHandler('stage', e.target.value)}
        />
      )}
      <div>
        <label htmlFor="mouseOver">滑入狀態</label>
        <input
          id="mouseOver"
          type="checkbox"
          checked={
            object.mouseOver === undefined ? false : object.mouseOver
          }
          onChange={e => inputTextHandler('mouseOver', e.target.checked)}
        />
      </div>
      {<OpacityComponent object={object} inputTextHandler={inputTextHandler} />}
      {<VisibleStatusComponent object={object} inputTextHandler={inputTextHandler} />}
      {isSubmenu || <ClickableStatusComponent object={object} inputTextHandler={inputTextHandler} />}
      {object.contentType === InteractiveObjectContentType.GoPage && (
        <Input
          type="number"
          label="頁數"
          value={object.pageIndex}
          min="1"
          onChange={e => inputTextHandler('pageIndex', e.target.value)}
        />
      )}
      {object.contentType === InteractiveObjectContentType.Video && (
        <VideoComponent
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.VideoInfo, info)
          }
        />
      )}
      {object.contentType === InteractiveObjectContentType.AreaZoom && (
        <AreaZoomComponent object={object} />
      )}
      {object.contentType ===
        InteractiveObjectContentType.InternalHtmlWindow && (
          <InternalHtmlWindowComponent
            object={object}
            saveProperty={
              info => inputTextHandler(ExtraFieldsForEditor.InternalHtmlInfo, info)
            }
          />
        )}
      {(object.contentType === InteractiveObjectContentType.WordGrid ||
        object.contentType === InteractiveObjectContentType.WordGlyph ||
        object.contentType === InteractiveObjectContentType.WordMeaning
      ) && (
          <WordGridComponent
            object={object}
            saveProperty={
              info => inputTextHandler(ExtraFieldsForEditor.WordGridInfo, info)
            }
          />
        )}
      {(object.contentType === InteractiveObjectContentType.VolumeOneWords
      ) && (
          <VolumeOneWordsComponent
            object={object}
            saveProperty={
              info => inputTextHandler(ExtraFieldsForEditor.VolumeOneWords, info)
            }
          />
        )}
      {object.contentType === InteractiveObjectContentType.WordExample && (
        <WordExampleComponent
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.WordExampleInfo, info)
          }
        />
      )}

      {object.contentType === InteractiveObjectContentType.Submenu && (
        <SubmenuPropertyForm
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.SubmenuInfo, info)
          }
        />
      )}
      {object.contentType === InteractiveObjectContentType.Vocabulary && (
        <VocabularyComponent
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.VocabularyInfo, info)
          }
        />
      )}
      {object.contentType === InteractiveObjectContentType.Hearing && (
        <HearingComponent
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.HearingInfo, info)
          }
        />
      )}
      {object.type === 'line' && <AdjustLineAngleComponent object={object} />}
      {object.contentType === InteractiveObjectContentType.Synopsis && (
        <SynopsisComponent
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.SynopsisInfo, info)
          }
        />
      )}
      {object.contentType === InteractiveObjectContentType.Listening && (
        <ListeningStoryComponent
          object={object}
          saveProperty={
            info => inputTextHandler(ExtraFieldsForEditor.ListeningInfo, info)
          }
        />
      )}
      {object.contentType === InteractiveObjectContentType.MathTools && (
        <MathToolsComponent object={object}/>
      )}
      {object.contentType === InteractiveObjectContentType.Audio && (
        <>
          <CheckBox
            checked={object.isAudioHighlighter}
            onChange={val => inputTextHandler('isAudioHighlighter', val)}
            className={styles.blackBorder}
          >隨文朗讀</CheckBox>
          <CheckBox
            checked={object.isFollowRead}
            onChange={val => inputTextHandler('isFollowRead', val)}
          >跟讀</CheckBox>
          {
            object.isAudioHighlighter ? (
              <Input
                label="物件id (.vtt)"
                value={object.audioInfo}
                onChange={e => inputTextHandler('audioInfo', e.target.value)}
              />
            ) : (
                <Input
                  label="字幕"
                  value={object.audioInfo}
                  onChange={e => inputTextHandler('audioInfo', e.target.value)}
                />
              )
          }

          {!object.audioInfo && !object.isAudioHighlighter && <AudioComponent
            value={object.partialSrc}
            onChange={value => inputTextHandler('partialSrc', value)}
          />}
          <label>
            <input
              type="checkbox"
              checked={
                object.isShowAudioControlBar !== false ? true : object.isShowAudioControlBar
              }
              onChange={e => inputTextHandler('isShowAudioControlBar', e.target.checked)}
            />
            顯示播放Bar
          </label>
          <Input
            label="字幕"
            value={object.audioInfo}
            onChange={e => inputTextHandler('audioInfo', e.target.value)}
          />
          {!object.audioInfo && <AudioComponent value={object.partialSrc} onChange={value => inputTextHandler('partialSrc', value)} />}
        </>
      )}
      {object.contentType === InteractiveObjectContentType.ReadAloud && (
        <Input
          label="字幕"
          value={object.readAloudInfo}
          onChange={e => inputTextHandler('readAloudInfo', e.target.value)}
        />
      )}
      {object.contentType === InteractiveObjectContentType.MeasureTools && (
        <MeasureToolsComponent object={object} />
      )}
    </div>
  );
};

export default PropertyForm;
