export const ReaderEvent = {
  SetPageIndexEvent: 'SetPageIndexEvent',
  ClickNextPageEvent: 'ClickNextPageEvent',
  ClickPreviousPageEvent: 'ClickPreviousPageEvent',
  ClickBookThumbnailEvent: 'ClickBookThumbnailEvent',
  RefreshCanvasEvent: 'RefreshCanvasEvent',
  CreateAndEnterAnnotationEvent: 'CreateAndEnterAnnotationEvent',
  ClickBookmarkEvent: 'ClickBookmarkEvent',
  AreaZoomEvent: 'AreaZoomEvent',
  SetAreaZoomIntoEvent: 'SetAreaZoomIntoEvent',
  AreaZoomForPageButtonEvent: 'AreaZoomForPageButtonEvent',
  DrawAreaEvent: 'DrawAreaEvent',
  GoBackCatalogEvent: 'GoBackCatalogEvent',
  SaveAnnotationNameEvent: 'SaveAnnotationNameEvent',
  DeleteAnnotationEvent: 'DeleteAnnotationEvent',
  CopyAnnotationEvent: 'CopyAnnotationEvent',
  GetLocalAnnotationEvent: 'GetLocalAnnotationEvent',
  ClickCanvasSVGObjectEvent: 'ClickCanvasSVGObjectEvent',
  SaveCanvasSVGObjectEvent: 'SaveCanvasSVGObjectEvent',
  DeleteCanvasSVGObjectInfoEvent: 'DeleteCanvasSVGObjectInfoEvent',
  SetCanvasSVGObjectIdEvent: 'SetCanvasSVGObjectIdEvent',
  SetSyncCameraEvnet: 'SetSyncCameraEvnet',
  SetFirebaseInfoEvent: 'SetFirebaseInfoEvent',
  SetStampCollectionEventEvent: 'SetStampCollectionEventEvent'
};

export const ReaderToolsEvent = {
  SetReaderToolsEvent: 'SetReaderToolsEvent',
  SetReaderToolHeight: 'SetReaderToolHeight',
  TogglePageModeEvent: 'TogglePageModeEvent',
  ToggleBookmarkEvent: 'ToggleBookmarkEvent',
  ClickDragEvent: 'ClickDragEvent',
  ClickSelectEvent: 'ClickSelectEvent',
  DrawSVGCanvasSwitchEvent: 'DrawSVGCanvasSwitchEvent',
  ClickPainterEvent: 'ClickPainterEvent',
  ClickEraserEvent: 'ClickEraserEvent',
  ClickInsertImageEvent: 'ClickInsertImageEvent',
  ClickInsertTextEvent: 'ClickInsertTextEvent',
  ToggleSideBarEvent: 'ToggleSideBarEvent',
  TogglePageSearcherEvent: 'TogglePageSearcherEvent',
  ClickReaderOptionPanelEvent: 'ClickReaderOptionPanelEvent',
  ClickStampPanelEvent: 'ClickStampPanelEvent',
  ClickOpenActivityEvent: 'ClickOpenActivityEvent',
  SetObjectPropertyEvent: 'SetObjectPropertyEvent',
  ChangeReaderToolStyleEvent: 'ChangeReaderToolStyleEvent',
  ChangeReaderToolDirectionEvent: 'ChangeReaderToolDirectionEvent',
  ChangeStampStatusEvent: 'ChangeStampStatusEvent',
  OnMarkModeEvent: 'OnMarkModeEvent',
  SelectMarkEvent: 'SelectMarkEvent',
  SaveMarkEvent: 'SaveMarkEvent',
  SetMarkToolsEnableEvent: 'SetMarkToolsEnableEvent',
  ClickAndNotifyEraseAllEvent: 'ClickAndNotifyEraseAllEvent',
  ClickEraseAllEvent: 'ClickEraseAllEvent',
  SetReaderZoomEvent: 'SetReaderZoomEvent',
  ZoomToolEvent: 'ZoomToolEvent',
  ClickFullWidthEvent: 'ClickFullWidthEvent',
  SaveReaderToolSettingsEvent: 'SaveReaderToolSettingsEvent',
  BackToCatalogEvent: 'BackToCatalogEvent',
  SetReaderToolTypeEvent: 'SetReaderToolTypeEvent',
  ChangePopWindowScaleEvent: 'ChangePopWindowScaleEvent',
  ChangePopWindowFontSizeEvent: 'ChangePopWindowFontSizeEvent',
  ChangePopWindowScrollPositionEvent: 'ChangePopWindowScrollPositionEvent',
  ChangePopWindowScrollPositionButtonStateEvent: 'ChangePopWindowScrollPositionButtonStateEvent',
  ClickOfflineReaderToolBoxEvent: 'ClickOfflineReaderToolBoxEvent',
  SetObjectPointerStatusEvent: 'SetObjectPointerStatusEvent',
  ClickMathToolsEvent: 'ClickMathToolsEvent',
  // SyncAnnotationToCloud: 'SyncAnnotationToCloud',
  ClickReaderToolBoxEvent: 'ClickReaderToolBoxEvent',
  CreateCourseInteractiveItemEvent: 'CreateCourseInteractiveItemEvent',
  ClickReaderToolStopwatchEvent: 'ClickReaderToolStopwatchEvent',
  ClickReaderToolTimerEvent: 'ClickReaderToolTimerEvent',
  PlayExpression: 'PlayExpression',
  ToggleFullScreen: 'ToggleFullScreen',
  ClosePannelEvent: 'ClosePannelEvent',
  ToggleStampCollectionBoxShowEvent: 'ToggleStampCollectionBoxShowEvent',
  SetCourseInfoEvent: 'SetCourseInfoEvent',
  SetOnCreateSVGObject: 'SetOnCreateSVGObject',
  SetFabricSVGElement: 'SetFabricSVGElement',
  SetFabricObjectTargetInfo: 'SetFabricObjectTargetInfo',
  ClickProtractor: 'ClickProtractor',
  SetExtendedContentPartialInfo: 'SetExtendedContentPartialInfo',
  SetSyncCameraEvent: 'SetSyncCameraEvent',
  SetSyncOnConnected: 'SetSyncOnConnected',
  SetSnackbarMessage: 'SetSnackbarMessage',
  UndoEvent: 'UndoEvent',
  RedoEvent: 'RedoEvent',
  TogglePaiNoteRecordEvent: 'TogglePaiNoteRecordEvent',
  SelectNoteEvent: 'SelectNoteEvent',
};

export const PainterEvent = {
  ChangeBrushColorEvent: 'ChangeBrushColorEvent',
  ChangeBrushWidthEvent: 'ChangeBrushWidthEvent',
  ChangeBrushTypeEvent: 'ChangeBrushTypeEvent',
  ChangePainterTypeEvent: 'ChangePainterTypeEvent',
  ChangeShapeFillTypeEvent: 'ChangeShapeFillTypeEvent',
  ChangelineTypeEvent: 'ChangelineTypeEvent',
  ChangeTextColorEvent: 'ChangeTextColorEvent',
  ChangeTextFontSizeEvent: 'ChangeTextFontSizeEvent',
  ChangeTextFontStyleEvent: 'ChangeTextFontStyleEvent',
  ChangeTextFontWeightEvent: 'ChangeTextFontWeightEvent',
  ChangeTextUnderlineEvent: 'ChangeTextUnderlineEvent',
  ChangeTextBgColorEvent: 'ChangeTextBgColorEvent'
};

export const CanvasEvent = {
  CanvasTextCreatedEvent: 'CanvasTextCreatedEvent',
  CanvasTextChangedEvent: 'CanvasTextChangedEvent',
  CanvasChooseImageSourceEvent: 'CanvasChooseImageSourceEvent',
  CanvasFinishPaintingEvent: 'CanvasFinishPaintingEvent',
  CanvasImportJSONEvent: 'CanvasImportJSONEvent',
  CanvasObjectSelectedEvent: 'CanvasObjectSelectedEvent',
  CanvasTextObjectSelectedEvent: 'CanvasTextObjectSelectedEvent',
  CanvasSelectionClearEvent: 'CanvasSelectionClearEvent',
  CanvasImageCreatedEvent: 'CanvasImageCreatedEvent',
  CanvasStampCreatedEventEvent: 'CanvasStampCreatedEventEvent',
  CreatedCanvasObjectEvent: 'CreatedCanvasObjectEvent',
  ModifyCanvasObjectPropertyEvent: 'ModifyCanvasObjectPropertyEvent',
  RemoveCanvasObjectEvent: 'RemoveCanvasObjectEvent',
  SetCanvasLevelEvent: 'SetCanvasLevelEvent',
  SetCanvasShadowEvent: 'SetCanvasShadowEvent',
  FlipCanvasEvent: 'FlipCanvasEvent',
  AlignCanvasGroupEvent: 'AlignCanvasGroupEvent',
  CanvasSavedEvent: 'CanvasSavedEvent',
  CanvasCloseMode: 'CanvasCloseMode',
  CanvasObjectCopyEvent: 'CanvasObjectCopyEvent',
  ToggleCanvasGroupEvent: 'ToggleCanvasGroupEvent',
  LockCanvasObjectEvent: 'LockCanvasObjectEvent',
  AdjustCanvasLineAngleEvent: 'AdjustCanvasLineAngleEvent',
  CanvasMeasureToolCreatedEvent: 'CanvasMeasureToolCreatedEvent'
};

export const UserEvent = {
  LoginEvent: 'LoginEvent',
  GetUserIdentityEvent: 'GetUserIdentityEvent'
};

export const ActivityEvent = {
  CreatActivityEvent: 'CreatActivityEvent',
  EnterActivityEvent: 'EnterActivityEvent',
  ChangeActivityTargetEvent: 'ChangeActivityTargetEvent',
  JoinActivityEvent: 'JoinActivityEvent',
  SendActivityInfoToFirebaseEvent: 'SendActivityInfoToFirebaseEvent',
  // GetActivityEvent: 'GetActivityEvent',
  OnAnnotationUpdateEvent: 'OnAnnotationUpdateEvent',
  UpdateActivityInfo: 'UpdateActivityInfo',
  LeaveActivityEvent: 'LeaveActivityEvent',
  ToggleActivityPanelEvent: 'ToggleActivityPanelEvent'
};

export const InteractiveObjectEvent = {
  ClickInteractiveObjectEvent: 'ClickInteractiveObjectEvent',
  ExportInteractiveObjectEvent: 'ExportInteractiveObjectEvent',
  SetInteractiveObjectStateEvent: 'SetInteractiveObjectStateEvent',
  SetStampCollection: 'SetStampCollection'
};


export const ExtendedContentEvent = {
  SetWordLearningGridPlaybackRateEvent: 'SetWordLearningGridPlaybackRateEvent',
};
