import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { preserveSVGAspectRatio } from 'util/book';
import {
  ReaderZoomType
} from 'constants/ReaderTools';



const BookPage = ({ content, bookInfo, pageIndex }) => {
  const [{ isDoublePageMode, isMarkModeShow, zoomType, offsetX, offsetY, scale }] = useStore(StoreTypes.reader);
  const [SVGSwitch, setSVGSwitch] = useState(false)
  const [switchInfo, setSwitchInfo] = useState({ x: offsetX, y: offsetY, s: scale })

  useEffect(() => {
    const { x, y, s } = switchInfo
    if (x != offsetX || y != offsetY || s !== scale) {
      setSVGSwitch(true)
      setTimeout(() => {
        setSVGSwitch(false)
      }, 17)
    }

  }, [zoomType, offsetX, offsetY, scale, switchInfo])

  return (
    <div className={classnames(styles.svgWrapper, {
      [styles.switch]: SVGSwitch
    })}>
      <div
        id='svgContent'
        className={classnames(styles.svgContent, {
          [styles.disableSelection]: !isMarkModeShow
        })}
        dangerouslySetInnerHTML={{
          __html: preserveSVGAspectRatio({
            svg: content,
            ...bookInfo,
            pageIndex,
            isDoublePageMode
          })
        }}
      />
    </div>
  );
};

export default BookPage;
