import React, { useRef, useEffect, useCallback,useState } from "react";
import MathJax from "react-mathjax2";
import { ExtendedContentButton } from "components/ExtendedContent/ExtendedContentButton";
import { ExtendedContentCanvas } from "components/ExtendedContent/ExtendedContentCanvas";
import { ExtendedFontSizeButton } from "components/ExtendedContent/ExtendedFontSizeButton";

import { ExtendedAnswersButton } from "components/ExtendedContent/ExtendedAnswersButton";
import { ExtendedPartialButton } from "components/ExtendedContent/ExtendedPartialButton";
import Snackbar from "components/Snackbar";
import {
  ExtendedContentTools,
  ExtendedContentContextMenu,
} from "components/ExtendedContent/ExtendedContentTools";
import { EventBus, EventBusType } from "events/EventBus";
import { ExtendedContentEvent } from "../ExtendedContentEvent";
import "./content.scss";
import classnames from "classnames";
import styles from "./index.module.scss";
import { Layout } from "./layout";
import Draggable from "react-draggable";
import { getOSAndBrowser } from "util/getOs";

import Button from "components/Button/index";
import { ReaderZoomType } from "constants/ReaderTools";
import { ReaderToolsEvent } from "events/EventTypes";
import Icon from "components/Icon";
import Repository from "repositories/Repository";
import { ExtendedContentScrollPositionButton, scrollPositionButtonBehavior } from "../ExtendedContentScrollPositionButton";
import { ScrollPositionType, ScrollLength,ScrollCoordinate } from "constants/scrollPositionType";
import { useStore, StoreTypes } from "context";
import { GroupInteractiveExtendedContentTool } from "components/GroupInteractiveTool/CustomExtendedContentTools";

const { ExtendedResourceRepository } = Repository;

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedContentType = {
  StaticHtml: "staticHtml",
  IFrame: "iframe",
  Vocabulary: "vocabulary",
  Video: "video",
  WordGrid: "wordGrid",
  WordGlyph: "wordGlyph",
  WordExample: "wordExample",
  Whiteboard: "whiteboard",
  NumberPicker: "numberPicker",
  Synopsis: "synopsis",
  MarkerWithSound: "MarkerWithSound",
  Translation: "translation",
  Hearing: "hearing",
  FlashCard: "flashCard",
  Listening: "listening",
  AboutTheAuthor: "aboutTheAuthor",
  ZhuyinLesson: "zhuyinLesson",
  ZhuyinImage: "zhuyinImage",
  ZhuyinWord: "zhuyinWord",
  WordGridSubContent: "wordGridSubContent",
  WordRadical: "wordRadical",
  AnswerInteractiveTool: "AnswerInteractiveTool",
  PaiNote: "PaiNote",
};

const FontSizeButtonWhiteList = [
  ExtendedContentType.Translation,
  ExtendedContentType.Hearing,
  ExtendedContentType.Vocabulary,
  ExtendedContentType.Listening,
  ExtendedContentType.AboutTheAuthor,
  ExtendedContentType.WordExample,
  ExtendedContentType.ZhuyinLesson,
  ExtendedContentType.WordGridSubContent,
  ExtendedContentType.WordGlyph,
  ExtendedContentType.WordRadical,
];

const AnswersButtonWhiteList = [ExtendedContentType.Listening];

const FullScreenWhiteList = [ExtendedContentType.WordGrid];

const groupInteractivity = [ExtendedContentType.AnswerInteractiveTool, ExtendedContentType.PaiNote];

const getCanvasSvg = (str) => {
  str = str.replace("<svg", '<svg preserveAspectRatio="xMidYMid"');
  return str;
};

export const ExtendedContentContainer = ({
  children,
  extendedResourceStyle,
  extendedContentType,
  onClose,
}) => {
  const contentView = useRef();
  const wrapperRef = useRef();
  const disablePopWindowScale = useRef(true);
  const contentRef = useRef();
  const [{ scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [
    {
      canvasState,
      canvasHeight,
      canvasWidth,
      isContentInit,
      svgContent,
      fontSize,
      isShowAnswer,
      popWindowScale,
      toolType,
      partialInfo,
    },
    { canvasDispatch },
  ] = ExtendedContentEvent(contentView);
  const exContainerRef = useRef(null);
  const popWinStyle = children.props
    ? children.props.extendedResourceStyle
    : null;

  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  // 判斷word直書樣式，顯示縮放按鈕與否
  useEffect(() => {
    const wordStyle = extendedResourceStyle
      ? extendedResourceStyle.split("_")[0]
      : "";
    if (wordStyle === "WordSection") {
      disablePopWindowScale.current = false;
    }
  }, [extendedResourceStyle]);

  useEffect(() => {
    // 小視窗內容上下頁分段
    const div = svgContent && !canvasState.isActive ? contentView.current.children[1] : contentView.current.children[0]
    // console.log("---E類小視窗---ExtendedContentContainer", div);
    if (div) {
      switch (scrollPosition) {
        case ScrollPositionType.Next: {
          setLastScrollTop(div.scrollTop);
          div.scrollTop += div.offsetHeight*ScrollLength;
          setCurrentScrollTop(div.scrollTop);
          break;
        }
        case ScrollPositionType.Prev: {
          setLastScrollTop(div.scrollTop);
          div.scrollTop -= div.offsetHeight*ScrollLength;
          setCurrentScrollTop(div.scrollTop);
          break;
        }
        default: {
          scrollPositionButtonBehavior({currentScroll: currentScrollTop, lastScroll: lastScrollTop, coordinate: ScrollCoordinate.Y})
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  useEffect(() => {
    if (popWinStyle && popWinStyle.match("WordSection") != null) {
      contentView.current.style.direction = "rtl";
    }
  }, [contentView, popWinStyle]);

  useEffect(() => {
    if (popWindowScale === 1) {
      wrapperRef.current.style.transform = "";
    }
  }, [popWindowScale]);

  const checkClassName = (className) => {
    switch (className) {
      case "tab":
      case "voca":
      case "list":
        return true;
      default:
        break;
    }
    return false;
  };

  const clearCanvas = useCallback((e) => {
    let tagType = e.target.tagName || '';
    let clearCanvas = false;
    switch (tagType.toLowerCase()) {
      case "div":
        const classNames = e.target.className.split("_");
        clearCanvas = checkClassName(classNames[1]);
        break;
      default:
        break;
    }
    clearCanvas &&
      EventBus.emit({
        eventBusType,
        event: ReaderToolsEvent.ClickEraseAllEvent,
      });
  }, []);

  return (
    <Draggable disabled={popWindowScale === 1 || canvasState.isActive}>
      <div
        ref={wrapperRef}
        onClick={(e) => {
          clearCanvas(e);
        }}
      >
        <div
          id="exContainer"
          className={classnames(
            styles.extendedContentContainer,
            styles[extendedContentType],
            styles[`cursor${toolType}`],
          )}
          ref={exContainerRef}
          style={{ transform: `scale(${popWindowScale})` }}
        >
          <link
            rel="stylesheet"
            type="text/css"
            href={Layout[extendedResourceStyle]}
          />
          {/* 判斷選用樣式“直書”並且作業系統為ＭＡＣ載入此樣式 */}
          {extendedResourceStyle === "WordSection" &&
          getOSAndBrowser() === "Mac" ? (
            <link
              rel="stylesheet"
              type="text/css"
              href="/layout/wordSection/wordSectionForMac.css"
            />
          ) : (
            ""
          )}

          {extendedResourceStyle === "WordSection_108_2" &&
            getOSAndBrowser() !== "Mac" && (
              <link
                rel="stylesheet"
                type="text/css"
                href="/layout/wordSection/wordSection108-2ForWin.css"
              />
            )}
          <div ref={contentView} className={classnames(styles.contentView, fontSize, {[styles.noPadding]:groupInteractivity.includes(extendedContentType)})}>
            {svgContent && !canvasState.isActive && (
              <div
                className={styles.svgContent}
                dangerouslySetInnerHTML={{
                  __html: getCanvasSvg(svgContent),
                }}
                ref={contentRef}
              />
            )}
            <MathJax.Context
            id="target"
            script={`${window.electron || window.ios || window.android?'':'/'}${ExtendedResourceRepository.getMathJaxPath()}?config=TeX-MML-AM_CHTML`}
            >
              <MathJax.Text text={children} />
            </MathJax.Context>
            {isContentInit && (
              <ExtendedContentCanvas
                {...{
                  canvasState,
                  canvasDispatch,
                  canvasWidth,
                  canvasHeight,
                }}
              />
            )}
          </div>
          {/* <ExtendedContentButton el={children} />
          <div className={styles.extendedButtonBox}>
            {FontSizeButtonWhiteList.includes(extendedContentType) && (
              <ExtendedFontSizeButton value={fontSize} />
            )}
            {AnswersButtonWhiteList.includes(extendedContentType) && (
              <ExtendedAnswersButton value={isShowAnswer} />
            )}
          </div> */}
          {groupInteractivity.includes(extendedContentType) && <GroupInteractiveExtendedContentTool extendedContentType={extendedContentType} />}
          {!groupInteractivity.includes(extendedContentType) && <ExtendedContentTools
            {...{
              extendedContentType,
              eventBusType,
              canvasState,
              popWindowScale,
              disablePopWindowScale: disablePopWindowScale.current,
              onClose,
            }}
          >
            {extendedContentType === "translation" && (
              <ExtendedPartialButton {...{ canvasState }} />
            )}
            <ExtendedContentButton el={children} />
            {FontSizeButtonWhiteList.includes(extendedContentType) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {extendedContentType !== "translation" && (
                  <ExtendedContentScrollPositionButton />
                )}
                <ExtendedFontSizeButton value={fontSize} />
              </div>
            )}
            {AnswersButtonWhiteList.includes(extendedContentType) && (
              <ExtendedAnswersButton value={isShowAnswer} />
            )}
            {FullScreenWhiteList.includes(extendedContentType) && (
              <Button
                onClick={() => {
                  EventBus.emit({
                    event: ReaderToolsEvent.SetReaderZoomEvent,
                    payload: { type: ReaderZoomType.OriginZoom },
                  });
                }}
                title="全螢幕"
                className={styles.unfocus}
              >
                <Icon name="AllScreen" />
                <div className={styles.text}>全螢幕</div>
              </Button>
            )}
          </ExtendedContentTools>}
          <ExtendedContentContextMenu {...{ eventBusType, canvasState }} />

          <Snackbar />
        </div>
      </div>
    </Draggable>
  );
};
